import { Component, LOCALE_ID } from '@angular/core';
import { AuthProvider } from 'ngx-auth-firebaseui';
import locale from '@angular/common/locales/bg';
import { registerLocaleData } from '@angular/common';
registerLocaleData(locale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'bg' }],
})
export class AppComponent {

  providers = AuthProvider;
}
